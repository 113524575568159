import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    HIDE_MESSAGE,
    SET_CURRENT,
    SHOW_MESSAGE
} from "../../constants/ActionTypes";
import axios from '../../util/Api';
//import { useHistory } from 'react-router-dom';


export const fetchStart = () => {
    return {
        type: FETCH_START
    }
};

export const fetchSuccess = () => {
    return {
        type: FETCH_SUCCESS
    }
};

export const fetchError = (error) => {
    return {
        type: FETCH_ERROR,
        payload: error
    }
};

export const showMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    }
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE
    }
};

export const setCurrent = (current) => {
    return {
        type: SET_CURRENT,
        payload: current
    }
}


export const fetchRecords = (url) => {
    //const history = useHistory();
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = "application/json";
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;


    return new Promise((resolve, reject) => {
        axios.get(url).then(({data}) => {
            resolve(data);
        }).catch(err => {
            //reject(err);
            console.log(err.response.status)
            if (err.response && err.response.status === 401) {
                localStorage.removeItem('token');
                 window.location.href = '/Home';
            } else {
                reject(err);
            }
        });
    });
};

export const saveRecords = (url, data) => {
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = " application/json";
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(({data}) => {

            resolve(data);

        }).catch(err => {
            //reject(err);
            console.log(err);
            console.log(err.response.status)
            if (err.response && err.response.status === 401) {
                //history.push('/Home');  // Redirect to the login page
                localStorage.removeItem('token');
                window.location.href = '/Home';
            } else {
                reject(err);
            }

        });
    });


};






